import React, { useState } from "react";
import { Form, Tab, Nav, Card } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CloudeFlareVideoUpload from "../../../Utils/CloudeFlareVideoUpload";
import ReactS3Ultra from "../../../Utils/ReactS3Ultra";
window.Buffer = window.Buffer || require("buffer").Buffer;

const AudioTab = ({ audioURLMr, setAudioURLMr, audioURL, setAudioURL, audioURLHn, setAudioURLHn }) => {
    const [fileEvent, setFileEvent] = useState(null);
    const [fileEventen, setFileEvenEN] = useState(null);
    const [fileEventhn, setFileEvenHN] = useState(null);

    // for language
    const [key, setkey] = useState('mr');

    const handleTabSelect = (key) => {
        setkey(key);
    };


    // Check if Marathi category are filled
    const isMarathiFilled = (
        (audioURLMr?.trim() !== '' && audioURLMr?.trim() !== '<p><br></p>')
    );

    // Check if English category are filled
    const isEnglishFilled = (
        (audioURL?.trim() !== '' && audioURL?.trim() !== '<p><br></p>')
    );
    // Check if Hindi category are filled
    const isHindiFilled = (
        ""
        // (audioURLHn?.trim() !== '' && audioURLHn?.trim() !== '<p><br></p>') 
    );
    return (
        <div className="ChromeTabs mb-4">
            <Tab.Container activeKey={key} onSelect={handleTabSelect}>
                <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                        <Nav.Link eventKey="mr" className={isMarathiFilled ? "filled-tab" : ""}>
                            Marathi
                            {isMarathiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="en" className={isEnglishFilled ? "filled-tab" : ""}>
                            English {isEnglishFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="hn" className={isHindiFilled ? "filled-tab" : ""} disabled>
                            Hindi {isHindiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>


                <Tab.Content>
                    <Tab.Pane eventKey="mr" className="mt-3">
                        <div data-aos="fade-up">
                            <>
                                {!audioURLMr ?
                                    <>
                                        <label htmlFor="marathiAudio" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />Upload Audio
                                        </label>
                                        <input type="file" id="marathiAudio" onChange={(event) => { setFileEvent(event) }} accept="audio/*" style={{ display: "none" }} />
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="uploadedFile m-1">
                                            <audio src={audioURLMr} controls className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setAudioURLMr('')} />
                                        </Card>
                                    </div>
                                }
                            </>

                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="en" className="mt-3">
                        {
                            key === "en" &&
                            <div data-aos="fade-up">
                                <>
                                    {!audioURL ?
                                        <>
                                            <label htmlFor="englishAudio" id="uploadBtn">
                                                <Icon.CloudArrowUp size={20} className="me-2" />Upload Audio
                                            </label>
                                            <input type="file" id="englishAudio" onChange={(event) => { setFileEvenEN(event) }} accept="audio/*" style={{ display: "none" }} />
                                        </>
                                        :
                                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                            <Card className="uploadedFile m-1">
                                                <audio src={audioURL} controls className='mx-auto' />
                                                <Icon.XCircleFill className='removeIcon' size={18}
                                                    onClick={() => setAudioURL('')} />
                                            </Card>
                                        </div>
                                    }
                                </>

                            </div>
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="hn" className="mt-3" >
                        {
                            key === "hn" &&
                            <div data-aos="fade-up">
                                <>
                                    {!audioURLHn ?
                                        <>
                                            <label htmlFor="hindiAudio" id="uploadBtn">
                                                <Icon.CloudArrowUp size={20} className="me-2" />Upload Audio
                                            </label>
                                            <input type="file" id="hindiAudio" onChange={(event) => { setFileEvenHN(event) }} accept="audio/*" style={{ display: "none" }} />
                                        </>
                                        :
                                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                            <Card className="uploadedFile m-1">
                                                <audio src={audioURLHn} controls className='mx-auto' />
                                                <Icon.XCircleFill className='removeIcon' size={18}
                                                    onClick={() => setAudioURLHn('')} />
                                            </Card>
                                        </div>
                                    }
                                </>
                            </div>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>

            {
                fileEvent &&
                <ReactS3Ultra fileEvent={fileEvent} fileType="audio"
                    directory="audioMarathi" onFileUpload={(data) => setAudioURLMr(data)} type="" />
            }
            {
                fileEventen &&
                <ReactS3Ultra fileEvent={fileEventen} fileType="audio"
                    directory="audioEnglish" onFileUpload={(data) => setAudioURL(data)} type="" />
            }
            {
                fileEventhn &&
                <ReactS3Ultra fileEvent={fileEventhn} fileType="audio"
                    directory="audioHindi" onFileUpload={(data) => setAudioURLHn(data)} type="" />
            }
        </div >
    )
}

export default AudioTab