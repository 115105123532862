import React, { useState } from 'react';
import { Col, Form, Row, Button, Spinner, Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import CategoryTab from '../../Common/langugageToggle/CategoryTab';

const EditMoood = () => {
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);

    const location = useLocation();
    const moodData = location?.state?.item;

    // state for input field
    const [MoodEn, setMoodEn] = useState(moodData?.moodTitleLang?.en || "")
    const [MoodMr, setMoodMr] = useState(moodData?.moodTitleLang?.mr || "")
    const [MoodHn, setMoodHn] = useState(moodData?.moodTitleLang?.hin || "")
    const [MoodImgUrl, setMoodImgUrl] = useState(moodData?.moodImgURL || "")
    const [priority, setPriority] = useState(moodData?.priorityNo)
    const [fileimageEvent, setFileImageEvent] = useState('');

    // state for loader
    const [loader, setLoader] = useState(false)
    // state for error
    const [error, setErrors] = useState({});

    const handleReactS3UltraResIcon = (url) => {
        setMoodImgUrl(url);
    }

    // for validation
    const validate = () => {
        let newErrors = {};

        if ((!MoodEn || MoodEn.trim() === '<p><br></p>') ||
            (!MoodMr || MoodMr.trim() === '<p><br></p>')
            // ||(!MoodHn || MoodHn.trim() === '<p><br></p>')
        ) {
            newErrors.mood = "*Mood name in English and Marathi are required";
        }

        if (!MoodImgUrl) {
            newErrors.MoodImgUrl = "* Image is Required"
        }


        if (!priority) {
            newErrors.priority = "*Please set priority"
        }


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // for add mood data
    const updateMood = async (status) => {
        const isValid = validate();
        if (!isValid) return;
        setLoader(true);
        try {
            let payload = {
                moodId: moodData?.moodId,
                moodTitle: MoodEn,
                moodTitleLang: {
                    en: MoodEn,
                    mr: MoodMr,
                    hin: MoodHn,
                },
                moodImgURL: MoodImgUrl,
                moodMessage: "",
                moodMessageLang: { en: "", mr: "", hin: "" },
                priorityNo: priority,
                isPublish: status
            }
            const res = await fetch(process.env.REACT_APP_BASE_URL + "/mood/updateMood", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify(payload),
            })

            const response = await res.json();
            if (response.message === "Mood updated successfully") {
                toast.success("Updated Successfully !");
                navigate("/dashboard/mood")
            } else if (response.message === "Mood already exists") {
                toast.error("Mood already exists");
            }
        } catch (Err) {
            console.log("Err while adding Mood", Err);
            toast.error('Failed to create Mood. Please try again.');
        } finally {
            setLoader(false);
        }
    }

    return (
        <>
            <div className="outletPadding">
                <Container>
                    <Row>
                        <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />Back
                        </Button>
                    </Row>

                    <h3 className='text-center d-flex justify-content-center align-items-center'>
                        <Icon.Clipboard2Plus size={20} className='me-2' />Edit Mood
                    </h3>
                    <Row className='mt-4'>
                        <CategoryTab
                            categoryEn={MoodEn}
                            setCategoryEn={setMoodEn}
                            categoryMr={MoodMr}
                            setCategoryMr={setMoodMr}
                            categoryHn={MoodHn}
                            setCategoryHn={setMoodHn}
                            label="Mood"
                            placeholder="मूड"
                        />
                        {error.mood && (
                            <p className="errMsg">{error.mood}</p>
                        )}

                        <Col md={6}>
                            <Form.Group className="mb-3" id="img">
                                <Form.Label>Mood Image<span>*</span></Form.Label> <br />
                                {!MoodImgUrl ?
                                    <>
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />Upload Mood Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(event) => setFileImageEvent(event)} />
                                        <p id='imgNote'>Recommended image resolution - 1:1</p>
                                        {error.MoodImgUrl && <p className="errMsg">{error.MoodImgUrl}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="uploadedFile m-1">
                                            <img src={MoodImgUrl} style={{ width: 60, height: 60 }} alt="Uploaded URL" className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setMoodImgUrl('')} />
                                        </Card>
                                    </div>
                                }
                            </Form.Group>
                        </Col>


                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Priority<span>*</span></Form.Label>
                                <Form.Control
                                    value={priority}
                                    placeholder="Enter priority"
                                    onChange={(e) => {
                                        if (
                                            !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)
                                        ) { setPriority(e.target.value); }
                                    }}
                                />

                                {error.priority && <p className="error">{error.priority}</p>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-5'>
                        {
                            loader ? <center><Spinner variant='primary' /></center> :
                                <>
                                    <ButtonComponent
                                        onSave={updateMood}
                                        onSaveAndPublish={updateMood}
                                        saveLabel="Update"
                                        savePublish="Update & Publish"
                                    />
                                </>

                        }
                    </Row>
                </Container>
                {
                    fileimageEvent &&
                    <ReactS3Ultra fileEvent={fileimageEvent} onFileUpload={handleReactS3UltraResIcon} />
                }

            </div>
        </>
    )
}

export default EditMoood
