import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from 'react-bootstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ChromeTabShortDesc from '../../Common/langugageToggle/ChromeTabShortdesc';
import ButtonComponent from "../../Common/Button/ButtonComponent"
import { typeActivityList } from '../../../Utils/GlobalConfigs';
import CloudeFlareVideoUpload from '../../../Utils/CloudeFlareVideoUpload';
import AudioTab from '../../Common/langugageToggle/AudioTab';
import useContentType from '../../Common/Reuse/useContentType';
import useGetPageList from '../../Common/Reuse/useGetPageList';
import useGetMoodList from '../../Common/Reuse/useGetMoodList';

const EditActivity = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const activity = location.state;

  // call custom hook for page title
  const { pageData } = useGetPageList();
  const { moodData } = useGetMoodList();

  const [time, meridiem] = activity.activityDayTime?.split(" ");
  const [hours, minutes] = time.split(":");


  // call customr hook for content type
  const { contenttypelist } = useContentType()

  // state for loader
  const [editLoader, setEditLoader] = useState(false);

  // state for input field
  const [titleEn, setTitleEn] = useState(activity?.titleLang?.en);
  const [titleMr, setTitleMr] = useState(activity?.titleLang?.mr);
  const [titleHn, setTitleHn] = useState(activity?.titleLang?.hin);
  const [Video, SetVideo] = useState(activity?.contentData?.contentLang?.en || "");
  const [contentType, setContentType] = useState({ label: activity?.contentType, value: activity?.contentType })
  const [audioURL, setAudioURL] = useState(activity?.contentData?.contentLang?.en || "");
  const [audioURLMr, setAudioURLMr] = useState(activity?.contentData?.contentLang?.mr || "");
  const [audioURLHn, setAudioURLHn] = useState(activity?.contentData?.contentLang?.hin || "");
  const [img, setImg] = useState(activity.imageURL);
  const [point, setPoint] = useState(activity.point);
  const [duration, setDuration] = useState(activity.timeInMin);
  const [hrs, setHrs] = useState(hours);
  const [min, setMin] = useState(minutes);
  const [timeZone, setTimeZone] = useState(meridiem);
  const [type, setType] = useState({ label: activity.type, value: activity.type });
  const [calories, setCalories] = useState(activity.calories);
  const [tagCategory, setTagCategory] = useState({ label: activity?.tagsCategory, value: activity?.tagsCategory })
  const [tags, setTags] = useState(
    activity?.tags?.map((tag) => ({ label: tag, value: tag })) || []
  );
  const [shortdescription, setShortDescription] = useState(activity?.shortDescriptionLang?.en);
  const [shortdescriptionMr, setShortDescriptionMr] = useState(activity?.shortDescriptionLang?.mr);
  const [shortdescriptionHn, setShortDescriptionHn] = useState(activity?.shortDescriptionLang?.hin);
  const [tagCatgoriesList, setTagCategoriesList] = useState([]);
  const [tagList, setTagsList] = useState([]);
  const [error, setErrors] = useState({});
  const [fileEvent, setFileEvent] = useState('');
  const [mood, setMood] = useState(activity?.moods?.map(moodItem => ({ label: moodItem, value: moodItem })) || [])
  const [pageTitle, setpageTitle] = useState({ label: activity?.pageName, value: activity?.pageId, Lang: activity?.pageNameLang })


  useEffect(() => {
    getTagCategories();
  }, [])

  useEffect(() => {
    if (tagCategory) {
      getTagList();
    }
  }, [tagCategory])

  const getTagCategories = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTagCat', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userState.data.token}`
        },
      });
      const responseData = await response.json();
      let arr = [];
      responseData?.data?.map((item) => {
        arr.push({
          ...item,
          label: item.tagCategoryName,
          value: item.tagCategoryId,
        });
      });
      setTagCategoriesList(arr);
    }
    catch (Err) {
      console.log("Err while getting tag categories", Err);
    }
  }

  const getTagList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTags2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({ tagCategory: tagCategory?.value })

      });
      const responseData = await response.json();
      let arr = [];
      responseData?.data?.map((item) => {
        arr.push({
          ...item,
          label: item.tag,
          value: item.tagId,
        });
      });
      setTagsList(arr);
    }
    catch (Err) {
      console.log("Err while getting tag categories", Err);
    }
  }

  // ------ Upload Image ----------------------------------

  const handleReactS3UltraRes = (url) => {
    setImg(url);
  }


  const uploadOnCloudFlare = async (data) => {
    SetVideo(data?.preview);
  }

  // for validation
  const editValidate = () => {
    let newErrors = {};

    if (!titleEn) {
      newErrors.titleEn = "*Title in english is required";
    }

    if (!titleMr) {
      newErrors.titleMr = "*मराठीत शीर्षक आवश्यक आहे";
    }

    // if (!titleHn) {
    //   newErrors.titleHn = "*शीर्षक हिन्दी में आवश्यक है";
    // }

    // if (!pageTitle) {
    //   newErrors.pageTitle = "*Page Title is Required";
    // }

    if (!img) {
      newErrors.img = "*Please upload image";
    }

    // if (!point) {
    //   newErrors.point = "*Points is required";
    // }

    if (!duration) {
      newErrors.duration = "*Activity duration is required";
    }

    if (!hrs && !min && !timeZone) {
      newErrors.startTime = "*Activity start time is required";
    }

    if (!type) {
      newErrors.type = "*Type is required";
    }

    // if (!calories) {
    //   newErrors.calories = "*Calories is required";
    // }

    if (!tagCategory) {
      newErrors.tagCategory = "*Tag Category is required";
    }

    if (tags.length === 0) {
      newErrors.tags = "*Tags is required";
    }

    if (mood.length === 0) {
      newErrors.mood = "*Required Field";
    }

    if (!contentType) {
      newErrors.contentType = "*Required field"
    }

    if (contentType?.value === "Physical Activity") {
      if (!Video) {
        newErrors.Video = "*Please upload video";
      }
    }

    if (contentType?.value === "Audio") {
      if ((!audioURLMr) ||
        (!audioURL))
      //  ||(!audioURLHn) ||
      {
        newErrors.audio = "*audio in English and Marathi are required";
      }
    }

    // // Validate short description based on the selected language
    if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
      (!shortdescription || shortdescription.trim() === '<p><br></p>'))
    //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') ||
    {
      newErrors.description = "* descriptions in English and Marathi are required";
    }

    setErrors(newErrors);
    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }

  // for edit data
  const editActivity = async (status) => {
    const isValid = editValidate();
    if (!isValid) return;
    setEditLoader(true);
    try {
      const res = await fetch(process.env.REACT_APP_BASE_URL + '/activity/updateActivity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userState.data.token}`
        },
        body: JSON.stringify({
          activityId: activity.activityId,
          title: titleEn,
          titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
          contentType: contentType?.value,
          contentData: {
            content: contentType?.value === "Physical Activity" ? Video : contentType?.value === "Audio" ? audioURL : '',
            contentLang: {
              en: contentType?.value === "Physical Activity" ? Video : contentType?.value === "Audio" ? audioURL : '',
              mr: contentType?.value === "Physical Activity" ? Video : contentType?.value === "Audio" ? audioURLMr : '',
              hin: contentType?.value === "Physical Activity" ? Video : contentType?.value === "Audio" ? audioURLHn : '',
            }
          },
          imageURL: img,
          point: point,
          timeInMin: duration,
          activityDayTime: hrs + ":" + min + " " + timeZone,
          type: type?.value,
          calories: calories,
          tagsCategory: tagCategory?.label,
          priorityNo: "100",
          tags: tags.map((row) => row.label),
          description: "",
          descriptionLang: { en: "", mr: "", hin: "" },
          shortDescription: shortdescriptionMr,
          shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
          isHome: true,
          moods: mood.map((item) => item?.label),
          moodId: mood.map((item) => item?.value),
          createdBy: userState?.data?.user?.name,
          isPublish: status,
          pageId: pageTitle?.value ? pageTitle?.value : "",
          pageName: pageTitle?.label ? pageTitle?.label : "",
          pageNameLang: pageTitle?.Lang,
        })
      });

      const response = await res.json();
      if (response.message === "Updated Successfully") {
        toast.success("Updated Successfully");
        setEditLoader(false);
        navigate(-1);
      }
    } catch (Err) {
      console.log("Error While Updating Activity", Err);
      setEditLoader(false);
    }
  }

  return (
    <div className='AddActivity outletPadding'>
      <ToastContainer />
      <Container>
        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-2" />Back
          </Button>
        </Row>

        <h3 className='text-center d-flex justify-content-center align-items-center'>
          <Icon.Clipboard2Plus size={20} className='me-2' />Edit Activity
        </h3>

        <Row className="mt-4">
          <Col md={6} >
            <Form.Group className='mb-3'>
              <Form.Label>Title in English<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter Title in English" id="titleEn" value={titleEn}
                onChange={(e) => {
                  if (!e.target.value.match(/[\u0900-\u097F]/) &&
                    e.target.value.trim()
                  ) {
                    setTitleEn(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleEn(e.target.value);
                  }
                }}
              />
              {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Title in Marathi<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="titleMr" value={titleMr}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:/\/()1234567890{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleMr(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleMr(e.target.value);
                  }
                }} />
              {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
            </Form.Group>
          </Col>

          <Col md={4} className='off'>
            <Form.Group className='mb-4'>
              <Form.Label>Title in Hindi<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="शीर्षक दर्ज करें" id="titleHn" value={titleHn}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:/\/()1234567890{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleHn(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleHn(e.target.value);
                  }
                }} />
              {error.titleHn && <p className="errMsg">{error.titleHn}</p>}
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="mb-3" id="img">
              <Form.Label>Activity Image<span>*</span></Form.Label> <br />
              {!img ?
                <>
                  <label htmlFor="activityImg" id="uploadBtn">
                    <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    id="activityImg"
                    style={{ display: "none" }}
                    onChange={(event) => setFileEvent(event)} />
                  <p id='imgNote'>Recommended image resolution - 3:2</p>
                  {error.img && <div className="errMsg">{error.img}</div>}
                </>
                :
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  <Card className="uploadedFile m-1">
                    <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                    <Icon.XCircleFill className='removeIcon' size={18}
                      onClick={() => setImg('')} />
                  </Card>
                </div>
              }
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Content Type<span>*</span></Form.Label>
              <Select
                value={contentType}
                onChange={(selectedOption) => setContentType(selectedOption)}
                options={contenttypelist}
                placeholder="Select content Type"
                isSearchable
              />
              {error.contentType && <div className="error">{error.contentType}</div>}
            </Form.Group>
          </Col>

          {contentType?.value === "Physical Activity"
            ? <Col md={6}>
              <Form.Group className="mb-3" id="Video">
                <Form.Label>Upload Video<span>*</span></Form.Label> <br />
                {
                  !Video ?
                    <>
                      <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} orientation="portrait" />
                      {error.Video && <p className="errMsg">{error.Video}</p>}
                    </>
                    :
                    <div className="d-flex" style={{ flexWrap: "wrap" }}>

                      <Card className="d-flex uploadedFile justify-content-center align-items-center me-4">
                        <a target="_blank" href={Video}>
                          <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                        </a>
                        <Icon.XCircleFill className='removeIcon' size={18}
                          onClick={() => SetVideo("")} />
                      </Card>

                    </div>
                }
              </Form.Group>
            </Col>
            : ''}

          {contentType?.value === "Audio" ? (
            <Col md={6}>
              <AudioTab
                audioURLMr={audioURLMr}
                setAudioURLMr={setAudioURLMr}
                audioURL={audioURL}
                setAudioURL={setAudioURL}
                audioURLHn={audioURLHn}
                setAudioURLHn={setAudioURLHn}
              />
              {error.audio && (
                <p className="errMsg mt-1">{error.audio}</p>
              )}
            </Col>
          ) : null}

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Activity Duration (Min)<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter duration in Min" id="duration" value={duration} onChange={(e) => {
                if (e.target.value.match(/^[0-9]+$/)) {
                  setDuration(e.target.value);
                } else if (e.target.value.length === 0) {
                  setDuration(e.target.value);
                }
              }} />
              {error.duration && <p className="errMsg">{error.duration}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Activity Start Time<span className='errMsg'>*</span></Form.Label>
              <div className='d-flex justify-content-between'>
                <Form.Select className='me-3' value={hrs} id="startTime" onChange={(e) => setHrs(e.target.value)}>
                  <option value='' disabled>Hours</option>
                  {
                    Array.from({ length: 12 }, (_, index) =>
                      (<option value={index + 1}>{index + 1}</option>))
                  }
                </Form.Select>

                <Form.Select className='me-3' id="startTime" value={min} onChange={(e) => setMin(e.target.value)}>
                  <option value='' disabled>Min</option>
                  {
                    Array.from({ length: 60 }, (_, index) =>
                      (<option value={index}>{index}</option>))
                  }
                </Form.Select>

                <Form.Select value={timeZone} id="startTime" onChange={(e) => setTimeZone(e.target.value)}>
                  <option value='' disabled>Zone</option>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
              </div>
              {error.startTime && <p className="errMsg">{error.startTime}</p>}
            </Form.Group>
          </Col>


          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Type<span className='errMsg'>*</span></Form.Label>
              <Select isMulti={false} id="type" options={typeActivityList} isSearchable={true} value={type}
                placeholder={<div style={{ fontSize: '15px' }}>Select Type</div>}
                onChange={(option) => { setType(option); }} />
              {error.type && <p className="errMsg">{error.type}</p>}
            </Form.Group>
          </Col>


          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Calories</Form.Label>
              <Form.Control placeholder="Enter calories" id="calories" value={calories} onChange={(e) => {
                if (e.target.value.match(/^[0-9]+$/)) {
                  setCalories(e.target.value);
                } else if (e.target.value.length === 0) {
                  setCalories(e.target.value);
                }
              }} />
              {/* {error.calories && <p className="errMsg">{error.calories}</p>} */}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Tag Category<span className='errMsg'>*</span></Form.Label>
              <Select id="tagCategory" isMulti={false} options={tagCatgoriesList} isSearchable={true} value={tagCategory}
                placeholder={<div style={{ fontSize: '15px' }}>Select Tag Category</div>}
                onChange={(option) => { setTagCategory(option); }} />
              {error.tagCategory && <p className="errMsg">{error.tagCategory}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Tags<span className='errMsg'>*</span></Form.Label>
              <Select id="tags" isDisabled={tagCategory === ""} isMulti={true} options={tagList} isSearchable={true} value={tags}
                closeMenuOnSelect={false} placeholder={<div style={{ fontSize: '15px' }}>Select Tags</div>}
                onChange={(option) => { setTags(option); }} />
              {error.tags && <p className="errMsg">{error.tags}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Page</Form.Label>
              <Select
                isMulti={false}
                options={pageData}
                value={pageTitle}
                onChange={(option) => {
                  setpageTitle(option);
                }}
              />
              {/* {error.pageTitle && (<p className="error">{error.pageTitle}</p>)} */}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Mood<span>*</span></Form.Label>
              <Select
                isMulti={true}
                isSearchable={true}
                placeholder="Select mood"
                value={mood}
                onChange={(option) => setMood(option)}
                options={moodData}
              />
              {error.mood && <div className="error">{error.mood}</div>}
            </Form.Group>
          </Col>


          {/* for description  */}
          <ChromeTabShortDesc
            shortdescriptionMr={shortdescriptionMr}
            setShortDescriptionMr={setShortDescriptionMr}
            shortdescription={shortdescription}
            setShortDescription={setShortDescription}
            shortdescriptionHn={shortdescriptionHn}
            setShortDescriptionHn={setShortDescriptionHn}
            label="Short"
          />

          {error.description && (
            <p className="errMsg mt-1">{error.description}</p>
          )}

        </Row>

        <Row className='mb-5'>
          {
            editLoader ? <center><Spinner variant='primary' /></center> :
              <>
                <ButtonComponent
                  onSave={editActivity}
                  onSaveAndPublish={editActivity}
                  saveLabel="Update"
                  savePublish="Update & Publish"
                />
              </>
          }
        </Row>
      </Container>

      {
        fileEvent &&
        <ReactS3Ultra fileEvent={fileEvent} onFileUpload={handleReactS3UltraRes} type={4 / 5} />
      }
    </div>
  )
}

export default EditActivity;




