import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Button, Spinner, Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from "react-select";
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import CategoryTab from '../../Common/langugageToggle/CategoryTab';
import { categoryTypes } from '../../../Utils/GlobalConfigs';

const EditCategory = () => {
    const navigate = useNavigate();
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);
    const location = useLocation();
    const categoryData = location?.state?.item;

    const [error, setErrors] = useState({});
    const [categoryEn, setCategoryEn] = useState(categoryData?.tagCategoryNameLang?.en || "");
    const [categoryMr, setCategoryMr] = useState(categoryData?.tagCategoryNameLang?.mr || "");
    const [categoryHn, setCategoryHn] = useState(categoryData?.tagCategoryNameLang?.hin || "");
    const [iconUrl, setIconUrl] = useState(categoryData?.iconURL || "");
    const [imageurl, setImageurl] = useState(categoryData?.imgURL || "");
    const [categoryType, setCategoryType] = useState(categoryData?.categoryType || "");
    const [loader, setLoader] = useState(false);
    const [fileEvent, setFileEvent] = useState('');
    const [fileimageEvent, setFileImageEvent] = useState('');
    const [iconcolor, setIconColor] = useState(categoryData?.backgroundColor);
    const [imagecolor, setImageColor] = useState(categoryData?.headerColor);
    const [priority, setPriority] = useState(categoryData?.priorityNo)

    useEffect(() => {
        setCategoryType({
            label: categoryData?.categoryType || "",
            value: categoryData?.categoryType || ""
        });
    }, [categoryData]);


    const handleReactS3UltraResIcon = (url) => {
        setIconUrl(url);
    }

    const handleReactS3UltraResImage = (url) => {
        setImageurl(url)
    }

    const emptyState = () => {
        setCategoryEn("");
        setCategoryHn("");
        setCategoryMr("");
        setCategoryType("");
        setIconUrl("");
    }

    const validate = () => {
        let newErrors = {};

        if ((!categoryEn || categoryEn.trim() === '<p><br></p>') ||
            (!categoryMr || categoryMr.trim() === '<p><br></p>')
            // ||(!categoryHn || categoryHn.trim() === '<p><br></p>')
        ) {
            newErrors.category = "*TagCategory name in English and Marathi are required";
        }

        if (!iconUrl) {
            newErrors.iconUrl = "* Icon is Required"
        }

        if (!imageurl) {
            newErrors.imageurl = "* Image is Required"
        }

        if (!categoryType) {
            newErrors.categoryType = "*TagCategory Type is Required";
        }

        if (!priority) {
            newErrors.priority = "*Please set priority"
        }

        if (!iconcolor) {
            newErrors.iconcolor = "* Please select at lease one icon color"
        }

        if (!imagecolor) {
            newErrors.imagecolor = "* Please select at lease one image color"
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    };

    const editCategory = async (status) => {

        const isValid = validate();
        if (!isValid) return;

        setLoader(true);
        try {
            let payload = {
                tagCategoryId: categoryData?.tagCategoryId,
                tagCategoryName: categoryEn,
                tagCategoryNameLang: {
                    en: categoryEn,
                    mr: categoryMr,
                    hin: categoryHn,
                },
                iconURL: iconUrl,
                imgURL: imageurl,
                categoryType: categoryType.value,
                priorityNo: priority,
                backgroundColor: iconcolor,
                headerColor: imagecolor,
                updatedBy: userState?.user?.userId,
                isPublish: status
            }

            const res = await fetch(process.env.REACT_APP_BASE_URL + "/tagcat/updateTagCat", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify(payload),
            });

            const response = await res.json();
            if (response.message === "Updated Successfully") {
                toast.success("Tag Category Updated Successfully !");
                navigate("/dashboard/category", { state: { updatedCategoryId: categoryData?.tagCategoryId } });
                emptyState();
                setLoader(false);
            }
            else {
                toast.error("Failed to edit category.");
                setLoader(false);
            }
        } catch (Err) {
            console.log("error", Err);
            setLoader(false);
        }
    }

    return (
        <>
            <div className="outletPadding">
                <Container>
                    <Row>
                        <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />Back
                        </Button>
                    </Row>

                    <h3 className='text-center d-flex justify-content-center align-items-center'>
                        <Icon.Clipboard2Plus size={20} className='me-2' />Edit Tag Category
                    </h3>
                    <Row className='mt-4'>
                        <CategoryTab
                            categoryEn={categoryEn}
                            setCategoryEn={setCategoryEn}
                            categoryMr={categoryMr}
                            setCategoryMr={setCategoryMr}
                            categoryHn={categoryHn}
                            setCategoryHn={setCategoryHn}
                            label="TagCategory"
                            placeholder="श्रेणी"
                        />
                        {error.category && (
                            <p className="errMsg">{error.category}</p>
                        )}
                        <Col md={6} >
                            <Form.Group className="mb-3">
                                <Form.Label>Content Type<span>*</span></Form.Label>
                                <Select
                                    value={categoryType}
                                    options={categoryTypes}
                                    onChange={(option) => setCategoryType(option)}
                                />
                                {error.categoryType && <p className="error">{error.categoryType}</p>}
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Priority<span>*</span></Form.Label>
                                <Form.Control
                                    value={priority}
                                    placeholder="Enter priority"
                                    onChange={(e) => {
                                        if (
                                            !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)
                                        ) { setPriority(e.target.value); }
                                    }}
                                />

                                {error.priority && <p className="error">{error.priority}</p>}
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3" id="img">
                                <Form.Label>Tag Category Icon<span>*</span></Form.Label> <br />
                                {!iconUrl ?
                                    <>
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />Upload Icon Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(event) => setFileEvent(event)} />
                                        <p id='imgNote'>Recommended image resolution - 1:1</p>
                                        {error.iconUrl && <p className="errMsg">{error.iconUrl}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="uploadedFile m-1">
                                            <img src={iconUrl} style={{ width: 60, height: 60 }} alt="Uploaded URL" className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setIconUrl('')} />
                                        </Card>
                                    </div>
                                }
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Select Icon Background Color<span>*</span></Form.Label>
                                <Form.Control
                                    type="color"
                                    placeholder="Select icon background color"
                                    value={iconcolor}
                                    onChange={(e) => setIconColor(e.target.value)
                                    }
                                />
                                {error.iconcolor && <p className="errMsg">{error.iconcolor}</p>}
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3" id="img">
                                <Form.Label>Tag Category Image<span>*</span></Form.Label> <br />
                                {!imageurl ?
                                    <>
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(event) => setFileImageEvent(event)} />
                                        <p id='imgNote'>Recommended image resolution - 1:1</p>
                                        {error.imageurl && <p className="errMsg">{error.imageurl}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="uploadedFile m-1">
                                            <img src={imageurl} style={{ width: 60, height: 60 }} alt="Uploaded URL" className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setImageurl('')} />
                                        </Card>
                                    </div>
                                }
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Select Image Header Color<span>*</span></Form.Label>
                                <Form.Control
                                    type="color"
                                    placeholder="select image header color"
                                    value={imagecolor}
                                    onChange={(e) => setImageColor(e.target.value)
                                    }
                                />
                                {error.imagecolor && <p className="errMsg">{error.imagecolor}</p>}
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row className='mb-5'>
                        {
                            loader ? <center><Spinner variant='primary' /></center> :
                                <>
                                    <ButtonComponent
                                        onSave={editCategory}
                                        onSaveAndPublish={editCategory}
                                        saveLabel="Update"
                                        savePublish="Update & Publish"
                                    />
                                </>

                        }
                    </Row>

                </Container>
                {
                    fileEvent &&
                    <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
                        directory="icon" onFileUpload={handleReactS3UltraResIcon} />
                }

                {
                    fileimageEvent &&
                    <ReactS3Ultra fileEvent={fileimageEvent} onFileUpload={handleReactS3UltraResImage} />
                }
            </div>
        </>
    )
}

export default EditCategory
