import S3 from "react-aws-s3";
import imageCompression from "browser-image-compression";;
window.Buffer = window.Buffer || require("buffer").Buffer;

const ReactS3 = async (file, credentials, directory) => {

  try {
    let S3Link;
    //----- Config ---------------------------------
    const config = {
      bucketName: credentials?.bucket,
      dirName: directory,
      region: credentials?.region,
      accessKeyId: credentials?.accessKey,
      secretAccessKey: credentials?.secretKey,
      // s3Url: credentials?.s3Url,
    };

    //---- Setting up aws S3 Client -----------
    const ReactS3Client = new S3(config);

    if (file?.type === "video/mp4") {
      console.log(file);
      let size = 3;
      let kb = 1024;
      let mb = kb * 1024;
      // if (size < mb * 3) {

      let fileNameWithExt = file.name.replace(/ /g, '_');
      let newFileName = fileNameWithExt.split(".")[0] + "/" + Date.now();

      await ReactS3Client.uploadFile(file, newFileName)
        .then((res) => {
          if (res.status === 204) {
            S3Link = res?.location;
            console.log("Video Uploded successfully");
          } else {
            console.log("AWS Error");

          }
        })
        .catch((err) => {
          console.error("Err while uploading img on S3", err);
        });
      // } else {
      //   alert("Upload Video size less than 3 mb");
      // }
    } else if (file.type.split("/")[0] === "image") {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      let fileNameWithExt = file.name.replace(/ /g, '_');
      let newFileName = fileNameWithExt.split(".")[0] + "/" + Date.now();

      await ReactS3Client.uploadFile(compressedFile, newFileName)
        .then((res) => {
          if (res.status === 204) {
            S3Link = res?.location;

          } else {
            console.log("AWS Error");

          }
        })
        .catch((err) => {
          console.error("Err while uploading img on S3", err);
        });

    } else {
      let size = 3;
      let kb = 1024;
      let mb = kb * 1024;
      if (size < mb * 3) {
        let fileNameWithExt = file.name.replace(/ /g, '_');
        let newFileName = fileNameWithExt.split(".")[0] + "/" + Date.now();

        await ReactS3Client.uploadFile(file, newFileName)
          .then((res) => {
            if (res.status === 204) {
              S3Link = res?.location;

              alert("Doc Uploded successfully");

            } else {
              console.log("AWS Error");

            }
          })
          .catch((err) => {
            console.error("Err while uploading img on S3", err);
          });
      } else {
        alert("DOC size less than 3 mb");
      }
    }
    return S3Link;
  } catch (error) {
    throw error;
  }
};

export default ReactS3;






