import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Card, Modal, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Skeleton from '../../Common/Skeleton';
import { getAllCategories } from '../../Common/Categories';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';
import GuruNameTab from '../../Common/langugageToggle/GuruNameTab';


const Guru = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData?.data);
  const getData = useSelector(state => state?.categories?.categoryList?.data);
  const navigate = useNavigate();
  const dispatch = useNavigate();

  useEffect(() => {
    dispatch(getAllCategories({ payload: { categoryType: "guru" }, token: userState.token, }))
  }, [])

  const guruCategoryOptions = getData?.data !== undefined &&
    getData?.data !== null &&
    getData?.data?.length > 0 ?
    getData.data.map((itm) => {
      return ({ label: itm.tagCategoryName, value: itm.tagCategoryName })
    }) : []


  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [delId, setDelId] = useState('');
  const [guruId, setGuruId] = useState('');
  const [showDel, setShowDel] = useState(false);

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const [firstNameEn, setFirstNameEn] = useState('');
  const [firstNameMr, setFirstNameMr] = useState('');
  const [firstNameHn, setFirstNameHn] = useState('');
  const [lastNameEn, setLastNameEn] = useState('');
  const [lastNameMr, setLastNameMr] = useState('');
  const [lastNameHn, setLastNameHn] = useState('');
  const [img, setImg] = useState('');
  const [category, setCategory] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [fileEvent, setFileEvent] = useState('');
  const [color, setColor] = useState('#000000')
  const [error, setErrors] = useState({});

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    getAllGurus()
  }, [pageNo, docPerPage])

  // ------- Get All Activities ----------------------
  const getAllGurus = async () => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${userState?.token}`
        },
        body: JSON.stringify({
          type: "Guru",
          title: searchField ? searchField : "",
          documentsPerPage: docPerPage,
          page: pageNo
        })
      })
      const responseData = await response.json();
      if (responseData.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(responseData);
        setNoOfPages(responseData?.noOfPages);
        setLoader(false);
      }

    } catch (Err) {
      console.log("Err while getting activites", Err);
      setLoader(false);
    }
  }

  // for debounding
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllGurus, dependencies, setLoader);

  // ------ Upload Image ----------------------------------
  const handleReactS3UltraRes = (url) => {
    setImg(url);
  }


  const addValidate = () => {
    let newErrors = {};

    if ((!firstNameEn || firstNameEn.trim() === '<p><br></p>') ||
      (!lastNameEn || lastNameEn.trim() === '<p><br></p>') ||
      (!firstNameMr || firstNameMr.trim() === '<p><br></p>') ||
      (!lastNameMr || lastNameMr.trim() === '<p><br></p>'))
    //  || (!firstNameHn || firstNameHn.trim() === '<p><br></p>') ||
    //      (!lastNameHn || lastNameHn.trim() === '<p><br></p>')) 
    {
      newErrors.description = "*Both firstname and lastname in English and Marathi are required";
    }

    if (!img) {
      newErrors.img = "*Please upload image";
    }

    if (category.length === 0) {
      newErrors.category = "*Please select guru category";
    }

    if (!color) {
      newErrors.color = "* Please select at lease one color"
    }

    setErrors(newErrors);
    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }

  const addGuru = async () => {
    const isValid = addValidate();
    if (isValid) {
      try {
        setLoader(true);
        const result = await fetch(process.env.REACT_APP_BASE_URL + '/guru/createGuru', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
          body: JSON.stringify({
            firstName: firstNameMr,
            lastName: lastNameMr,
            firstNameLang: { en: firstNameEn, mr: firstNameMr, hin: firstNameHn },
            lastNameLang: { en: lastNameEn, mr: lastNameMr, hin: lastNameHn },
            imgURL: img,
            categories: category?.map((row) => row?.value),
            backgroundColor: color,
            createdBy: userState?.user?.name
          })
        });

        const response = await result.json();
        if (response?.message === "Guru Created Successfully") {
          toast.success("Guru Created Successfully", { toastId: '123456' });
          setLoader(false);
          closeModal();
          getAllGurus();
        }
        else if (response?.message === "Guru already exist") {
          toast.error("Guru already exist", { toastId: '1234567' });
          setLoader(false);
        }
        else {
          toast.error("Failed to add guru, please try again");
          setLoader(false);
          closeModal();
        }
      } catch (Err) {
        console.log("Error  in adding Guru", Err);
        setLoader(false);
        closeModal();
      }
    }
  }

  const editGuru = async () => {
    const isValid = addValidate();
    if (isValid) {
      try {
        setLoader(true);
        const result = await fetch(process.env.REACT_APP_BASE_URL + '/guru/updateGuru', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
          body: JSON.stringify({
            guruId: guruId,
            firstName: firstNameMr,
            lastName: lastNameMr,
            firstNameLang: { en: firstNameEn, mr: firstNameMr, hin: firstNameHn },
            lastNameLang: { en: lastNameEn, mr: lastNameMr, hin: lastNameHn },
            imgURL: img,
            categories: category?.map((row) => row?.value),
            backgroundColor: color,
            updatedBy: userState?.user?.userId
          })
        });
        const response = await result.json();
        if (response?.message === "Updated Successfully") {
          toast.success("Guru Updated Successfully", { toastId: '123456' });
          setLoader(false);
          closeModal();
          getAllGurus();
        }
        else if (response?.message === "Guru already exist") {
          toast.error("Guru already exist", { toastId: '1234567' });
          setLoader(false);
        }
        else {
          toast.error("Failed to add guru, please try again");
          setLoader(false);
          closeModal();
        }
      } catch (Err) {
        console.log("Error  in adding Guru", Err);
        setLoader(false);
        closeModal();
      }
    }
  }

  const deleteGuru = async () => {
    setLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/guru/deleteGuru', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userState.token}`
        },
        body: JSON.stringify({ guruId: delId })
      });
      const response = await result.json();
      if (response.message === 'Data deleted succussfully') {
        toast.success('Guru Deleted Succussfully', { toastId: '12345678' });
        setShowDel(false);
        setDelId('');
        setLoader(false);
        getAllGurus();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting guru", Err);
      setLoader(false);
    }
  }

  const addEdit = (itm) => {
    setShowEdit(true);
    const { guruId, firstNameLang, lastNameLang, imgURL, categories } = itm;
    setGuruId(guruId);
    setFirstNameEn(firstNameLang.en);
    setFirstNameMr(firstNameLang.mr);
    setFirstNameHn(firstNameLang.hin);
    setLastNameEn(lastNameLang.en);
    setLastNameMr(lastNameLang.mr);
    setLastNameHn(lastNameLang.hin);
    setImg(imgURL);
    setCategory(categories.map((e) => {
      return {
        "label": e, "value": e
      }
    }));
  }


  // function for pagination -------------
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }

  //----- Setting up Closing modal & Clearing Values ----//
  const closeModal = () => {
    setFirstNameEn('');
    setFirstNameMr('');
    setFirstNameHn('');
    setLastNameEn('');
    setLastNameMr('');
    setLastNameHn('');
    setImg('');
    setCategory([]);
    setShowAdd(false);
    setShowEdit(false);
  }


  return (
    <div className='Guru'>

      <DeleteModel
        show={showDel}
        onHide={() => { setShowDel(false); setDelId(''); }}
        onDelete={deleteGuru}
        loader={loader}
        label="Guru"
      />
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>

          <Col md={3} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => setShowAdd(true)}>
              <Icon.ClipboardPlus className='me-2' size={16} />Add Guru
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '65vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Image</th>
                <th>Name</th>
                <th>Categories</th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={10} cols={6} /> :
                  data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {
                    const { imgURL, firstName, lastName, categories, status, guruId } = itm
                    return (

                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (
                          <>
                            {' '}
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </>
                        ) : (
                          <>{index + 1}</>
                        )}</td>
                        <td><img src={imgURL} alt="Guru Image" width={40} /></td>
                        <td>{firstName + ' ' + lastName}</td>
                        <td>{categories.length > 0 ? categories?.map((row) => row).join(', ') : '-'}</td>
                        <td>{status}</td>
                        <td className="d-flex">
                          <img src={Edit} className='icon me-3'
                            onClick={() => { addEdit(itm) }}
                          />
                          <img src={Delete} className='icon'
                            onClick={() => { setShowDel(true); setDelId(guruId); }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <PaginationSequence
          data={data?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />
      </Container>


      {/*  ------------------- Add Guru Modal ------------------------ */}
      <Modal size='lg' show={showAdd} backdrop='static' keyboard={false} centered onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title> <h4 className='mb-0'>
            <Icon.PersonAdd size={20} className='me-2' />Add Guru</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            {/* for firstname and lastname  */}
            <GuruNameTab
              firstNameMr={firstNameMr}
              setFirstNameMr={setFirstNameMr}
              firstNameEn={firstNameEn}
              setFirstNameEn={setFirstNameEn}
              firstNameHn={firstNameHn}
              setFirstNameHn={setFirstNameHn}
              lastNameMr={lastNameMr}
              setLastNameMr={setLastNameMr}
              lastNameEn={lastNameEn}
              setLastNameEn={setLastNameEn}
              lastNameHn={lastNameHn}
              setLastNameHn={setLastNameHn}
            />

            {error.description && (
              <p className="errMsg">{error.description}</p>
            )}
            <Col md={4}>
              <Form.Group className="mb-3" id="img">
                <Form.Label>Guru Image<span>*</span> <p id='imgNote'>(Upload only png image)</p></Form.Label> <br />
                {!img ?
                  <>
                    <label htmlFor="guruImg" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                    </label>

                    <input type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="guruImg"
                      style={{ display: "none" }}
                      onChange={(event) => setFileEvent(event)} />
                    <p id='imgNote'>Recommended image resolution - 1:1</p>
                    {error.img && <div className="errMsg">{error.img}</div>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setImg('')} />
                    </Card>
                  </div>
                }
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Guru Categories<span>*</span></Form.Label>
                <Select
                  isMulti={true}
                  id="category"
                  value={category}
                  onChange={setCategory}
                  options={guruCategoryOptions}
                  placeholder="Select categories"
                />
                {error.category && <p className="errMsg">{error.category}</p>}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Background Color<span>*</span></Form.Label>
                <Form.Control
                  type="color"
                  placeholder="select color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)
                  }
                />
                {error.color && <p className="errMsg">{error.color}</p>}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {
            loader ? <Spinner variant='primary' /> :
              <Button className='primaryBtn' onClick={addGuru}>
                <Icon.PersonAdd className='me-1' />Add</Button>
          }
        </Modal.Footer>
      </Modal>


      {/*  ------------------- Edit Guru Modal ------------------------ */}
      <Modal size='lg' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title> <h4 className='mb-0'>
            <Icon.PersonAdd size={20} className='me-2' />Edit Guru</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            {/* for firstname and lastname  */}
            <GuruNameTab
              firstNameMr={firstNameMr}
              setFirstNameMr={setFirstNameMr}
              firstNameEn={firstNameEn}
              setFirstNameEn={setFirstNameEn}
              firstNameHn={firstNameHn}
              setFirstNameHn={setFirstNameHn}
              lastNameMr={lastNameMr}
              setLastNameMr={setLastNameMr}
              lastNameEn={lastNameEn}
              setLastNameEn={setLastNameEn}
              lastNameHn={lastNameHn}
              setLastNameHn={setLastNameHn}
            />

            {error.description && (
              <p className="errMsg">{error.description}</p>
            )}

            <Col md={4}>
              <Form.Group className="mb-3" id="img">
                <Form.Label>Guru Image<span>*</span> <p id='imgNote'>(Upload only png image)</p></Form.Label> <br />
                {!img ?
                  <>
                    <label htmlFor="guruImg" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                    </label>


                    <input type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="guruImg"
                      style={{ display: "none" }}
                      onChange={(event) => setFileEvent(event)} />
                    <p id='imgNote'>Recommended image resolution - 1:1</p>
                    {error.img && <div className="errMsg">{error.img}</div>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setImg('')} />
                    </Card>
                  </div>
                }
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Guru Categories<span>*</span></Form.Label>
                <Select
                  isMulti={true}
                  id="category"
                  value={category}
                  onChange={setCategory}
                  options={guruCategoryOptions}
                  placeholder="Select categories"
                />
                {error.category && <p className="errMsg">{error.category}</p>}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Background Color<span>*</span></Form.Label>
                {/* <ChromePicker
                  value={color}
                  color={color}
                  onChangeComplete={(newColor) => {
                    const isValidHex = /^#([0-9A-F]{6})$/i.test(newColor.hex);
                    if (isValidHex) setColor(newColor.hex);
                  }}
                /> */}
                <Form.Control
                  type="color"
                  placeholder="select color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)} />
                {error.color && <p className="errMsg">{error.color}</p>}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {
            loader ? <Spinner variant='primary' /> :
              <Button className='primaryBtn' onClick={editGuru}>
                <Icon.PersonAdd className='me-1' />Update</Button>
          }
        </Modal.Footer>
      </Modal>

      {
        fileEvent &&
        <ReactS3Ultra fileEvent={fileEvent} onFileUpload={handleReactS3UltraRes} />
      }
    </div>
  )
}

export default Guru;