import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ChromeTabShortDesc from '../../Common/langugageToggle/ChromeTabShortdesc';
import ButtonComponent from '../../Common/Button/ButtonComponent';

const EditTask = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const location = useLocation()
    const navigate = useNavigate()
    const { courseLessonId, lessonTitleLang, rowData } = location?.state

    const [loader, setLoader] = useState(false);
    const [createLoader, setCreateLoader] = useState(false);
    const [titleEn, setTitleEn] = useState(rowData?.titleLang?.en || "");
    const [titleMr, setTitleMr] = useState(rowData?.titleLang?.mr || "");
    const [titleHn, setTitleHn] = useState('');
    const [topicImages, settopicImages] = useState(rowData?.imageUrl || "");
    const [duration, setDuration] = useState(rowData?.duration || "");
    const [description, setDescription] = useState(rowData?.descriptionLang?.en || "");
    const [descriptionMr, setDescriptionMr] = useState(rowData?.descriptionLang?.mr || "");
    const [descriptionHn, setDescriptionHn] = useState('');
    const [shortdescription, setShortDescription] = useState("");
    const [shortdescriptionMr, setShortDescriptionMr] = useState("");
    const [shortdescriptionHn, setShortDescriptionHn] = useState("");
    const [maxpoint, setMaxPoint] = useState(rowData?.maxPoint || "");
    const [error, setErrors] = useState({});
    const [content, setContent] = useState(rowData?.content)
    const [fileEvent, setFileEvent] = useState('');


    const handleReactS3UltraRes = (url) => {
        settopicImages(url);
    }
    // for validation
    const createValidate = () => {
        let newErrors = {};

        if (!titleEn) {
            newErrors.titleEn = "*Title in English is required";
        }
        if (!titleMr) {
            newErrors.titleMr = "*Title in Marathi is required";
        }
        // if (!titleHeader) {
        //     newErrors.titleHeader = "*Title header is required";
        // }
        if (!topicImages) {
            newErrors.topicImages = "*Please upload at least one image";
        }

        if (!maxpoint) {
            newErrors.maxpoint = "*MaxPoint is required";
        }

        if (!duration) {
            newErrors.duration = "*duration in min is required";
        }
        if ((content.length === 0 || (content.length === 1 && content[0] === ''))) {
            newErrors.content = "*Content Type is required"
        }

        if ((!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
            (!description || description.trim() === '<p><br></p>'))
        // (!descriptionHn || descriptionHn.trim() === '<p><br></p>') ||
        {
            newErrors.description = "* descriptions in English and Marathi are required";
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    // for create course lesson model
    const updateCourseTask = async (status) => {
        const isValid = createValidate();
        if (!isValid) return;
        setCreateLoader(true);
        if (isValid) {
            await fetch(process.env.REACT_APP_BASE_URL + '/courseTask/updateCourseTask', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    taskId: rowData?.taskId,
                    entitytype: lessonTitleLang,
                    entitytypeLang: {
                        en: "",
                        mr: lessonTitleLang,
                        hin: ""
                    },
                    entityid: courseLessonId,
                    title: titleEn,
                    titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
                    description: descriptionMr,
                    descriptionLang: { en: description, mr: descriptionMr, hin: descriptionHn },
                    shortDescription: shortdescriptionMr,
                    shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                    duration: duration,
                    imageUrl: topicImages,
                    content: content,
                    createdBy: userState?.data?.user?.name,
                    maxPoint: maxpoint,
                    isPublish: status
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.message === "Updated Successfully") {
                        toast.success("Updated Successfully");
                        navigate("/dashboard/coursetopic", { state: { courseLessonId: response?.data?.updatedCourseTask?.entityid, lessonTitleLang: response?.data?.updatedCourseTask?.entitytypeLang?.mr } })
                        setCreateLoader(false);
                    }
                })
                .catch((Err) => {
                    console.log("Error While Creating CourseLesson", Err);
                    setCreateLoader(false);
                })
        }
    }
    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>
                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Edit Task

                </h3>
                <Row className='mt-4'>

                    <Col md={6} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Lesson Title </Form.Label>
                            <Form.Control value={lessonTitleLang?.en} disabled />

                        </Form.Group>
                    </Col>

                    {lessonTitleLang?.mr &&
                        <Col md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Lesson Title in Marathi</Form.Label>
                                <Form.Control value={lessonTitleLang?.mr} disabled />
                            </Form.Group>
                        </Col>}

                    {lessonTitleLang?.hin &&
                        <Col md={6} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Lesson Title in Hindi</Form.Label>
                                <Form.Control value={lessonTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>}

                    <Col md={6}>
                        <Form.Group className='mb-2'>
                            <Form.Label>Task Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title" id="" value={titleEn} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitleEn(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleEn(e.target.value);
                                }
                            }} />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-2'>
                            <Form.Label>Task Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Task Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Task Images<span>*</span></Form.Label> <br />
                            {!topicImages ?
                                <>
                                    <label htmlFor="fileInput" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={(event) => setFileEvent(event)} />
                                    <p id='imgNote'>Recommended image resolution - 1:1</p>
                                    {error.topicImages && <div className="errMsg">{error.topicImages}</div>}
                                </>
                                :
                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="uploadedFile m-1">
                                        <img src={topicImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => settopicImages('')} />
                                    </Card>
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>MaxPoint<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Maxpoint"
                                value={maxpoint}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setMaxPoint(e.target.value);
                                    }
                                }}
                            />
                            {error.maxpoint && <p className="errMsg">{error.maxpoint}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Duration (Min)<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter duration in Min" value={duration} onChange={(e) => {
                                if (e.target.value.match(/^[0-9]+$/)) {
                                    setDuration(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setDuration(e.target.value);
                                }
                            }} />
                            {error.duration && <p className="errMsg">{error.duration}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Content<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Content"
                                value={content.join('\n')}
                                onChange={(e) => {
                                    const lines = e.target.value.split('\n');
                                    setContent(lines);
                                }}

                            />
                        </Form.Group>
                        {error.content && <p className="errMsg">{error.content}</p>}
                    </Col>


                    {/* for description  */}
                    <ChromeTabShortDesc
                        shortdescriptionMr={descriptionMr}
                        setShortDescriptionMr={setDescriptionMr}
                        shortdescription={description}
                        setShortDescription={setDescription}
                        shortdescriptionHn={descriptionHn}
                        setShortDescriptionHn={setDescriptionHn}
                        label="Full"
                    />

                    {error.description && (
                        <p className="errMsg mt-1">{error.description}</p>
                    )}


                </Row>

                {
                    createLoader ? <Spinner variant='primary' className='mx-auto' /> :
                        <>
                            <ButtonComponent
                                onSave={updateCourseTask}
                                onSaveAndPublish={updateCourseTask}
                                saveLabel="Update"
                                savePublish="Update & Publish"
                            />
                        </>

                }
            </Container>

            {
                fileEvent &&
                <ReactS3Ultra fileEvent={fileEvent} onFileUpload={handleReactS3UltraRes} />
            }
        </div>
    )
}

export default EditTask
