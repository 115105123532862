import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { ReactCrop, makeAspectCrop, centerCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import * as Icon from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import S3 from "react-aws-s3";
import imageCompression from "browser-image-compression";
import { uploadImageFromBackend } from './uploadImage';
window.Buffer = window.Buffer || require("buffer").Buffer;

const ReactS3Ultra = ({ fileEvent, onFileUpload, type }) => {
  const [show, setShow] = useState(false);

  const [selectedImg, setSelectedImg] = useState('');
  const [crop, setCrop] = useState('');
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgBlob, setImgBlob] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [aspectRatio, setAspectRatio] = useState('');
  const [cropKey, setCropKey] = useState(Date.now());

  // for model close
  const handleClose = () => {
    setShow(false);
    setSelectedImg('');
    setCrop('');
    setImgBlob(null);
    setAspectRatio('');
    if (fileEvent?.target) {
      fileEvent.target.value = '';
    }
  };


  useEffect(() => {
    if (fileEvent) {
      handleImgEvent();
      if (type) {
        setAspectRatio(type);
      } else {
        setAspectRatio(1 / 1);
      }
    }
  }, [fileEvent]);


  useEffect(() => {
    setCropKey(prevKey => prevKey + Date.now());
  }, [aspectRatio])


  // for image,video upload
  const handleImgEvent = async () => {
    setImgBlob(null);
    const file = fileEvent?.target?.files[0];
    try {
      // Checking uploading right file or not
      if (!file || !(file.type.startsWith('image/') || file.type === 'video/mp4' || file.type === 'audio/mpeg' || file.type === 'audio/wav' || file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'image/gif')) {
        toast.error('Please select a valid image, video, audio, or document file', { toastId: 'invalid-file' });
        return;
      }

      // for video upload
      if (file.type === 'video/mp4') {
        let fileNameWithExt = file.name.replace(/ /g, '_');
        let newFileName = `${fileNameWithExt.split('.')[0]}/${Date.now()}`;

        const uploadedUrl = await uploadImageFromBackend(newFileName, file.type, file);
        if (uploadedUrl) {
          onFileUpload(uploadedUrl);
          toast.success('File uploaded successfully');
          setShow(false);
        } else {
          toast.error('Error uploading file, please try again');
        }
      }
      // for GIF upload
      else if (file.type === 'image/gif') {
        let fileNameWithExt = file.name.replace(/ /g, '_');
        let newFileName = `${fileNameWithExt.split('.')[0]}/${Date.now()}`;

        const uploadedUrl = await uploadImageFromBackend(newFileName, file.type, file);
        if (uploadedUrl) {
          onFileUpload(uploadedUrl);
          toast.success('File uploaded successfully');
          setShow(false);
        } else {
          toast.error('Error uploading file, please try again');
        }
      }
      // for audio upload
      else if (file.type.startsWith('audio/')) {
        let fileNameWithExt = file.name.replace(/ /g, '_');
        let newFileName = `${fileNameWithExt.split('.')[0]}/${Date.now()}`;

        const uploadedUrl = await uploadImageFromBackend(newFileName, file.type, file);
        if (uploadedUrl) {
          onFileUpload(uploadedUrl);
          toast.success('File uploaded successfully');
          setShow(false);
        } else {
          toast.error('Error uploading file, please try again');
        }
      }
      // for document upload
      else if (file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        let fileNameWithExt = file.name.replace(/ /g, '_');
        let newFileName = `${fileNameWithExt.split('.')[0]}/${Date.now()}`;

        const uploadedUrl = await uploadImageFromBackend(newFileName, file.type, file);
        if (uploadedUrl) {
          onFileUpload(uploadedUrl);
          toast.success('File uploaded successfully');
          setShow(false);
        } else {
          toast.error('Error uploading file, please try again');
        }
      }
      // for image upload
      else if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (fileEvent) => {
          setSelectedImg(fileEvent.target.result);
        };
        reader.onerror = (err) => {
          console.log(`Error reading image: ${err.message}`);
          toast.error('Error reading image');
        };
        reader.readAsDataURL(file);
        setShow(true);
      }

      else {
        toast.error("Unsupported file type. Please select an image, video, audio, or document.");
      }
    } catch (error) {
      toast.error('Error uploading file, Please try again');
    }
  };



  // for image load
  function onImageload(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const cropWidthInPercent = (150 / width) * 100;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: cropWidthInPercent,
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    )

    setCrop(crop);
  }


  const setCanvasPreview = (image, canvas, crop) => {
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error("No 2d Context");
    }
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
    canvas.toBlob(blob => {
      setImgBlob(blob)
    }, 'image/png');
    // handleSaveImage(canvas);
  }


  // ------ Upload Image ----------------------------------
  const handleUpload = async () => {
    setUploading(true);
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(imgBlob, options);
      let fileNameWithExt = fileEvent.target.files[0].name.replace(/ /g, '_');
      let newFileName = fileNameWithExt.split(".")[0] + "/" + Date.now();
      const fileType = fileEvent.target.files[0].type;
      console.log("filtype", fileType);


      const uploadedUrl = await uploadImageFromBackend(newFileName, fileType, compressedFile)
      if (uploadedUrl) {
        onFileUpload(uploadedUrl); // Call the callback with the uploaded file URL
        toast.success('File uploaded successfully');
        setShow(false); // Close the modal
      } else {
        toast.error('Error uploading file, please try again');
      }

    } catch (error) {
      toast.error('Error uploading file, Please try again');
    } finally {
      setUploading(false);
    }
  };


  return (
    <Modal show={show} size='lg' keyboard={false} backdrop={false} centered onHide={() => setShow(false)} >
      <Modal.Header closeButton onClick={handleClose} >
        <Modal.Title> <h4 className='mb-0'>
          <Icon.CloudUpload size={20} className='me-2' />React S3 Ultra Cropper & Uploader</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Select disabled
              className='w-75 mb-3' value={aspectRatio} onChange={(e) => setAspectRatio(e.target.value)}>
              <option value={1 / 1}>1/1</option>
              <option value={2 / 3}>2/3</option>
              <option value={3 / 2}>3/2</option>
              <option value={5 / 4}>5/4</option>
              <option value={4 / 5}>4/5</option>
              <option value={16 / 9}>16/9</option>
              <option value={2 / 1}>2/1</option>
              <option value={4 / 3}>4/3</option>
              <option value="">Free Crop</option>

            </Form.Select>

            <h6>Crop image here</h6>
            <ReactCrop key={cropKey} crop={crop} aspect={aspectRatio} minWidth={150}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}>
              <img
                src={selectedImg}
                ref={imgRef}
                alt="Crop Image"
                onLoad={onImageload}
                style={{ width: '300px' }} />
            </ReactCrop>
          </Col>

          <Col md={6}>
            <h6>Cropped Image</h6>
            <canvas ref={previewCanvasRef}
              className='m-auto' style={{
                border: "1px solid black",
                objectFit: "contain",
                width: 300,
                height: 300
              }} />
          </Col>

          <Col md={12} className='text-center mt-2'>
            <Button id="uploadBtn" onClick={() => {
              setCanvasPreview(
                imgRef.current, previewCanvasRef.current,
                convertToPixelCrop(
                  crop,
                  imgRef.current.width,
                  imgRef.current.height
                ))
            }}>
              <Icon.Crop className='me-2' />click here to crop
            </Button>
          </Col>
        </Row>
      </Modal.Body>


      <Modal.Footer>
        {uploading ? 'Uploading...' :
          <Button className='primaryBtn' disabled={imgBlob === null ? true : false} onClick={handleUpload}>
            <Icon.CloudArrowUp className='me-2' size={20} />Upload Image
          </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default ReactS3Ultra
