import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import { typeAgnihotraOptions } from '../../../Utils/GlobalConfigs';
import ChromeTabShortDesc from '../../Common/langugageToggle/ChromeTabShortdesc';
import CloudeFlareVideoUpload from '../../../Utils/CloudeFlareVideoUpload';

const AddAgnihotra = () => {

    const navigate = useNavigate();
    const userState = useSelector((state) => state?.cmsLogin?.userData);

    // usestate
    const [titleEn, setTitleEn] = useState('');
    const [titleMr, setTitleMr] = useState('');
    const [titleHn, setTitleHn] = useState('');
    const [titleHeaderEn, setTitleHeaderEn] = useState('');
    const [titleHeaderMr, setTitleHeaderMr] = useState('');
    const [titleHeaderHn, setTitleHeaderHn] = useState('');
    const [imageUrl, setImageUrl] = useState("");
    const [videourl, setVideoUrl] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionMr, setDescriptionMr] = useState('');
    const [descriptionHn, setDescriptionHn] = useState('');
    const [duration, setDuration] = useState('');
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [type, setType] = useState("")
    const [error, setErrors] = useState({});
    const [loader, setLoader] = useState(false)
    const [addAgnihotraFileEvent, setAgnihotraFileEvent] = useState('')

    // for validation
    const createValidate = () => {
        let newErrors = {};

        if (!titleEn) {
            newErrors.titleEn = "*Title in english is required";
        }

        if (!titleMr) {
            newErrors.titleMr = "*Title in marathi is required";
        }

        //  if (!titleHn) {
        //     newErrors.titleHn = "*Title in hindi is required";
        // }

        if (!titleHeaderEn) {
            newErrors.titleHeaderEn = "*Title header in english is required";
        }
        if (!titleHeaderMr) {
            newErrors.titleHeaderMr = "*Title header in marathi is required";
        }
        if (!imageUrl) {
            newErrors.imageUrl = "*Please upload at least one Images";
        }
        if (!videourl) {
            newErrors.videourl = "*Please upload at least one video";
        }

        if (!type) {
            newErrors.type = "*Please Select at least one type ";
        }

        if (!StartDate) {
            newErrors.StartDate = "*Start date is required";
        }

        if (!EndDate) {
            newErrors.EndDate = "*End date is required";
        }

        if (!duration) {
            newErrors.duration = "*Duration is required";
        }

        if ((!descriptionEn || descriptionEn.trim() === '<p><br></p>') ||
            (!descriptionMr || descriptionMr.trim() === '<p><br></p>'))
        // || (!descriptionHn || descriptionHn.trim() === '<p><br></p>')) 
        {
            newErrors.description = "* Descriptions in English and Marathi are required";
        }

        setErrors(newErrors);
        if (newErrors) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    }

    // for create agnihotra 
    const createAgnihotra = async (status) => {
        try {
            const isValid = createValidate();
            setLoader(true)
            if (isValid) {
                const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/createAgnihotraSteps', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                    body: JSON.stringify({
                        title: titleMr,
                        titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
                        titleHead: titleHeaderMr,
                        titleHeadLang: { en: titleHeaderEn, mr: titleHeaderMr, hin: titleHeaderHn },
                        imageURL: imageUrl,
                        imageURLLang: { en: imageUrl, mr: imageUrl, hin: imageUrl },
                        videoURL: videourl,
                        videoURLLang: { en: videourl, mr: videourl, hin: videourl },
                        duration: duration,
                        startTime: StartDate,
                        endTime: EndDate,
                        description: descriptionMr,
                        descriptionLang: { en: descriptionEn, mr: descriptionMr, hin: descriptionHn },
                        createdBy: userState?.data?.user?.name,
                        type: type?.value,
                        isPublish: status
                    })
                });

                const result = await response.json();
                if (result.message === "Agnihotra Steps Created Successfully") {
                    toast.success("Added Successfully");
                    navigate("/dashboard/agnihotra")
                }
                else if (result.message === "Agnihotra Steps already exist") {
                    toast.error("Agnihotra already exist");
                }
            }
        } catch (error) {
            console.log("Error While Creating Agnihotra", error);
        } finally {
            setLoader(false);
        }
    }

    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>

                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Add Agnihotra
                </h3>

                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title in english" id="titleEn" value={titleEn} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitleEn(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleEn(e.target.value);
                                }
                            }} />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="titleMr" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="titleHn" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                            {error.titleHn && <p className="errMsg">{error.titleHn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title header in english" id="titleHeader" value={titleHeaderEn}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleHeaderEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHeaderEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHeaderEn && <p className="errMsg">{error.titleHeaderEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title header in marathi" id="titleHeader" value={titleHeaderMr} onChange={(e) => {
                                const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                    setTitleHeaderMr(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleHeaderMr(e.target.value);
                                }
                            }} />
                            {error.titleHeaderMr && <p className="errMsg">{error.titleHeaderMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" id="img">
                            <Form.Label>Image<span>*</span></Form.Label> <br />
                            {!imageUrl ?
                                <>
                                    <label htmlFor="imageInput" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="imageInput"
                                        style={{ display: "none" }}
                                        onChange={(event) => setAgnihotraFileEvent(event)} />
                                    <p id='imgNote'>Recommanded image resolution - 1:1</p>
                                    {error.imageUrl && <div className="errMsg">{error.imageUrl}</div>}
                                </>
                                :
                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="uploadedFile m-1">
                                        <img src={imageUrl} style={{ width: 60, height: 60 }} className='mx-auto' />
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => setImageUrl('')} />
                                    </Card>
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" id="Video">
                            <Form.Label>Video<span>*</span></Form.Label> <br />
                            {
                                videourl?.length === 0 ?
                                    <>
                                        <CloudeFlareVideoUpload onFileUpload={(data) => setVideoUrl(data?.preview)} orientation="landscape" />
                                        {error.videourl && <p className="errMsg">{error.videourl}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="d-flex uploadedFile justify-content-center align-items-center me-4">
                                            <a target="_blank" href={videourl}>
                                                <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                            </a>
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setVideoUrl("")} />
                                        </Card>
                                    </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Agnihotra Type<span>*</span></Form.Label>
                            <Select
                                isMulti={false}
                                value={type}
                                onChange={(option) => setType(option)}
                                options={typeAgnihotraOptions}
                                placeholder="Select Agnihotra type"
                            />
                            {error.type && <p className="errMsg">{error.type}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Duration (Min)<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter duration in Min" id="duration" value={duration} onChange={(e) => {
                                if (e.target.value.match(/^[0-9]+$/)) {
                                    setDuration(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setDuration(e.target.value);
                                }
                            }} />
                            {error.duration && <p className="errMsg">{error.duration}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Date<span>*</span></Form.Label>
                            <Form.Control
                                className=""
                                type="date"
                                name="start Date"
                                value={StartDate}
                                min={new Date().toISOString().split("T")[0]}
                                max={EndDate}
                                onKeyDown={(event) => event.preventDefault()}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            {error.StartDate && <p className="errMsg">{error.StartDate}</p>}
                        </Form.Group>
                    </Col>

                    {/* End Date */}
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>End Date<span>*</span></Form.Label>
                            <Form.Control
                                className=""
                                type="date"
                                name="End Date"
                                value={EndDate}
                                min={StartDate}
                                onKeyDown={(event) => event.preventDefault()}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                            {error.EndDate && <p className="errMsg">{error.EndDate}</p>}
                        </Form.Group>
                    </Col>
                    {/* for description  */}
                    <ChromeTabShortDesc
                        shortdescriptionMr={descriptionMr}
                        setShortDescriptionMr={setDescriptionMr}
                        shortdescription={descriptionEn}
                        setShortDescription={setDescriptionEn}
                        shortdescriptionHn={descriptionMr}
                        setShortDescriptionHn={setDescriptionMr}
                    />

                    {error.description && (
                        <p className="errMsg mt-1">{error.description}</p>
                    )}
                </Row>

                {
                    loader ? <Spinner variant='primary' className='mx-auto' /> :
                        <>
                            <ButtonComponent
                                onSave={createAgnihotra}
                                onSaveAndPublish={createAgnihotra}
                                saveLabel="Save"
                                savePublish="Save & Publish"
                            />
                        </>
                }
            </Container>

            {
                addAgnihotraFileEvent &&
                <ReactS3Ultra fileEvent={addAgnihotraFileEvent} onFileUpload={(url) => setImageUrl(url)} type={1 / 1} />
            }

        </div>
    )
}

export default AddAgnihotra
